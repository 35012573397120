<mat-error *ngIf="errors['required']">Campo obrigatório</mat-error>
<mat-error *ngIf="errors['min']"> O valor mínimo é {{ errors['min'].min }} </mat-error>
<mat-error *ngIf="errors['max']"> O valor máximo é {{ errors['max'].max }} </mat-error>
<mat-error *ngIf="errors['minlength']">
  Tamanho mínimo de {{ errors['minlength'].requiredLength }} caracteres
</mat-error>
<mat-error *ngIf="errors['maxlength']"> Tamanho máximo {{ errors['maxlength'].requiredLength }} </mat-error>
<mat-error *ngIf="errors['mustMatchEmail']">Os e-mails não coincidem</mat-error>
<mat-error *ngIf="errors['mustMatchPassword']">As senhas não coincidem</mat-error>
<mat-error *ngIf="errors['cpfInvalid']">CPF inválido</mat-error>
<mat-error *ngIf="errors['dateInvalid']">Data inválida</mat-error>
<mat-error *ngIf="errors['dateInvalid']">Data inválida</mat-error>
<mat-error *ngIf="errors['cpfCnpjInvalid']">{{errors['cpfCnpjInvalid']}}</mat-error>
